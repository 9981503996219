<template>
  <v-container class="pt-0">
    <div style="text-align: center">
      <div width="100%">
        <img
          :height="$vuetify.breakpoint.mobile ? '250px' : '350px'"
          width="100%"
          contain
          cover
          centered
          :alt="user.profile.fullName"
          style="border-radius: 0px 0px 15px 15px; position: relative"
          src="https://firebasestorage.googleapis.com/v0/b/bayonapp-2021.appspot.com/o/notification20220111211857BAYON-App-Graphic.png?alt=media&token=8dc0d1f9-466b-488e-a73e-1d5b3aae3239"
        />
      </div>
      <div style="margin-top: -55px">
        <v-avatar size="130">
          <img
            alt="user"
            v-if="(user && user.url === '') || user.url === undefined"
            src="../../assets/user.png"
            class="custom-border-style"
          />
          <img
            :alt="user.profile.fullName"
            v-else
            :src="(user && user.url) || localStorage.url || ''"
            class="custom-border-style"
          />
          <a @click="$refs.profileUpload.click()">
            <v-icon
              dark
              style="
                position: absolute;
                bottom: 25px;
                right: 25px;
                transform: translate(50%, 50%);
                background-color: rgba(228, 230, 235, 0.3);
              "
            >
              mdi-camera
            </v-icon>
          </a>
          <input
            style="display: none !important"
            type="file"
            accept="image/*"
            @change="onFileSelected($event)"
            ref="profileUpload"
          />
        </v-avatar>
        <h1>
          {{
            (user && user.profile && user.profile.fullName) ||
            user.username ||
            ""
          }}
        </h1>
        <h3 style="color: teal">{{ user.userType || "" }}</h3>
        <br />
      </div>
    </div>
    <div
      v-if="loading"
      style="
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      "
    >
      <v-progress-circular
        style="text-align: center"
        :size="75"
        :width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>
    </div>
    <!-- old -->
    <v-row justify="center">
      <v-flex>
        <v-form
          :model="valid"
          ref="formData"
          lazy-validation
        >
          <v-row>
            <v-col cols="12" md="6">
              <v-col cols="12">
                <v-text-field
                  v-model="user.profile.fullName"
                  :rules="profileNameRules"
                  :label="$t('profileName')"
                  clearable
                  prepend-inner-icon="mdi-account-edit"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col cols="6" sm="6" md="6" style="padding-bottom: 0">
                    <v-autocomplete
                      v-model="user.phoneNumberPrefix"
                      :items="phoneNumberPrefixList"
                      :disabled="!!user.verificationCode"
                      :dense="dense"
                      color="blue-grey lighten-2"
                      :label="$t('countryCode')"
                      item-text="number"
                      item-value="number"
                      required
                      :rules="phoneNumberPrefixInput"
                      @change="focusOnPhoneInput"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                        >
                          <v-avatar left>
                            <v-img :src="data.item.flag" :alt="data.item.number"></v-img>
                          </v-avatar>
                          {{ data.item.number }}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-avatar>
                          <img :src="data.item.flag" :alt="data.item.number"/>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-subtitle
                            v-html="data.item.number"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                    style="padding-bottom: 0; padding-left: 0"
                  >
                    <v-text-field
                      id="phoneNumber"
                      v-model="user.phoneNumber"
                      :rules="phoneNumberInput"
                      :label="$t('phoneNumber')"
                      persistent-hint
                      required
                      :disabled="!!user.verificationCode"
                      placeholder="12563226"
                      maxlength="11"
                      :dense="dense"
                      autocomplete="off"
                      ref="phoneInput"
                      clearable
                      prepend-inner-icon="mdi-phone"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="$t('email')"
                  v-model="user.email"
                  :rules="emailRules"
                  clearable
                  prepend-inner-icon="mdi-email"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-dialog
                  ref="dialog"
                  v-model="modalDob"
                  :return-value.sync="user.dob"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="user.dob"
                      :label="$t('dob')"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="user.dob" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modalDob = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(user.dob)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="user.education"
                  :items="education"
                  item-text="label"
                  item-value="value"
                  :label="$t('education')"
                  persistent-hint
                  clearable
                  prepend-inner-icon="mdi-school"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.label }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.label }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="user.yearly_income"
                  :items="yearly_income"
                  item-text="label"
                  item-value="value"
                  :label="$t('yearlyIncome')"
                  persistent-hint
                  clearable
                  prepend-inner-icon="mdi-currency-usd"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.label }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.label }}
                  </template>
                </v-select>
              </v-col>
            </v-col>
            <v-col cols="12" md="6">
              <v-col cols="12" v-if="!isFreeMember">
                <v-text-field
                  v-model="user.address"
                  :label="$t('address')"
                  clearable
                  prepend-inner-icon="mdi-map-marker"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="user.telegram"
                  :label="$t('telegram')"
                  clearable
                  prepend-inner-icon="mdi-pencil"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="user.facebook"
                  :label="$t('facebook')"
                  clearable
                  prepend-inner-icon="mdi-facebook"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="user.occupation"
                  :items="occupation"
                  item-text="label"
                  item-value="value"
                  :label="$t('occupation')"
                  persistent-hint
                  clearable
                  prepend-inner-icon="mdi-briefcase"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.label }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.label }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="user.type"
                  :items="userType"
                  item-text="label"
                  item-value="value"
                  :label="$t('type')"
                  persistent-hint
                  clearable
                  prepend-inner-icon="mdi-account-tie"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.label }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.label }}
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="user.job_level"
                  :items="job_level"
                  item-text="label"
                  item-value="value"
                  :label="$t('jobLevel')"
                  persistent-hint
                  clearable
                  prepend-inner-icon="mdi-shape"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.label }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.label }}
                  </template>
                </v-select>
              </v-col>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="400px">
              <v-card>
                <v-card-title>
                  <span class="text-h6" style="text-align: justify"
                    >{{ $t("enterConfirmationCode") }}
                    {{ phoneNumberToConfirm }}</span
                  >
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-form
                      :model="codeValid"
                      ref="codeInputFormData"
                      lazy-validation
                    >
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            id="code"
                            v-model="user.smsCode"
                            :label="$t('code')"
                            :rules="verificationCodeRule"
                            persistent-hint
                            required
                            :disabled="!!user.verificationCode"
                            placeholder="123456"
                            maxlength="6"
                            :dense="dense"
                            autocomplete="off"
                            outlined
                            autofocus
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="cancelConfirmation">
                    {{ $t("cancel") }}
                  </v-btn>
                  <v-btn
                    text
                    @click="verifyCode"
                    color="blue darken-1"
                    :loading="loading"
                    v-shortkey="['enter']"
                    @shortkey.native="verifyCode"
                  >
                    {{ $t("confirm") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <reset-button @resetForm="resetForm" valid="false"></reset-button>
            <save-button id="submit-button" :valid="valid"></save-button>
          </v-card-actions>
        </v-form>
      </v-flex>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="depositForm" persistent max-width="400px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Please Enter Your Deposit Price</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="depositPrice"
                    label="Deposit Price*"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="depositForm = false">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="updateStatusWithPrice">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="soldForm" persistent max-width="400px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Please Enter Your Sold Price</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="soldPrice"
                    label="Sold Price*"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="soldForm = false">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="updateStatusWithPrice">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- -----------my property section--------------------------- -->
    <!-- <v-container> -->
      <hr />
      <br />
      <h2 style="color: purple" class="text-center">My Property</h2>
      <v-row v-if="propertyList.length > 0">
        <v-col cols="6" sm="4" md="3" v-for="d in propertyList" :key="d._id">
          <my-property-card 
          @renewProperty="renewProperty" 
          @removeProperty="removeProperty" 
          @updateStatus="updateStatus"
          :d="d"
          ></my-property-card>
        </v-col>
        <v-col cols="12" sm="12" md="12" style="text-align: center">
          <v-pagination
            v-model="page"
            :length="Math.ceil(totalPage / 12)"
            :total-visible="9"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" sm="12" md="12" style="text-align: center">
          No Property Found!
        </v-col>
      </v-row>
    <!-- </v-container> -->
  </v-container>
</template>

<script>
import SaveButton from "../../components/saveButton";
import ResetButton from "../../components/resetButton";
import "../../firebase/config";
import firebase from "firebase/app";
import "firebase/storage";
import "firebase/auth";
import moment from "moment";
import { provider } from "@/service/provider";
import axios from "axios";
import { Constants } from "../../libs/constant";
const Compress = require("compress.js");
import { countyMobileList } from "../../libs/countryMobileWithFlag";
import MainMixin from "../../mixins/mainMixin";
import _ from "lodash";
import myPropertyCard from '../../components/myPropertyCard.vue';

export default {
  mounted() {
    this.$jQuery("body").off();
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "submit-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log("verify " + response);
          this.handleSubmit();
        },
      }
    );
    window.recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });
  },
  mixins: [MainMixin],
  components: { SaveButton, ResetButton, myPropertyCard },
  data() {
    return {
      valid: true,
      uploadValue: 0,
      dialog: false,
      codeValid: null,
      dense: this.$store.state.isDense,
      eager: true,
      skip: 0,
      limit: 12,
      loading: false,
      page: 1,
      totalPage: 0,
      user: {
        _id: "",
        username: "",
        profile: {
          fullName: "",
        },
        userType: "",
        phoneNumber: "",
        phoneNumberPrefix: "",
        email: "",
        telegram: "",
        facebook: "",
        dob: "",
        type: "",
        education: "",
        occupation: "",
        yearly_income: "",
        job_level: "",
        url: "",
        staffId: "",
        planDoc: {
          description: "",
        },
        smsCode: "",
        address: "",
        agentId: "",
        agencyId: ""
      },
      agentDoc: {},
      userDoc: {},
      fileName: "",
      selectedFile: {},
      dataObj: {
        fullNameOnCard: "",
        bankName: "",
        bankNumber: "",
      },
      profileNameRules: [
        (v) => !!v || "Profile Name is required",
        (v) => (v && v.length) <= 20 || "Name must be less than 20 characters",
      ],
      requireRule: [(v) => !!v || "is required"],
      emailRules: [(v) => !v || /.+@.+\..+/.test(v) || "E-mail must be valid"],
      userType: Constants.userType,
      education: Constants.education,
      occupation: Constants.occupation,
      yearly_income: Constants.yearlyIncome,
      job_level: Constants.jobLevel,
      date: null,
      modalDob: false,
      phoneNumberPrefixList: countyMobileList,
      currentUser: "",
      verificationCodeRule: [
        (v) => !!v || "Please type the confirmation code",
        (v) =>
          /^[0-9]{6}$/im.test(v) || "Please type a valid confirmation code",
      ],
      phoneNumberPrefixInput: [(V) => !!V || "Please choose a country"],
      phoneNumberInput: [
        (v) => !!v || "Please type phone number",
        (v) => /^[0-9]{6,11}$/im.test(v) || "Please type a valid phone number",
      ],
      phoneNumberToFormat: "",
      phoneNumberPrefixToFormat: "",
      phoneNumberToConfirm: "",
      formattedPhoneNumber: "",
      isFreeMember: true,
      depositForm: false,
      soldForm: false,
      updateStatusOnPrice: "",
      soldPrice: "",
      depositPrice: "",
    };
  },

  methods: {
    resetForm() {
      this.$refs.formData.reset();
    },
    onFileSelected(e) {
      let vm = this;
      vm.fileName = e.target.files[0].name;
      const files = [...e.target.files];
      const compress = new Compress();
      compress
        .compress(files, {
          size: 4, // the max size in MB, defaults to 2MB
          quality: 0.9, // the quality of the image, max is 1,
          maxWidth: 1920, // the max width of the output image, defaults to 1920px
          maxHeight: 1920, // the max height of the output image, defaults to 1920px
          resize: true, // defaults to true, set false if you do not want to resize the image width and height
        })
        .then((data) => {
          const img1 = data[0];
          const base64str = img1.data;
          const imgExt = img1.ext;
          vm.selectedFile = Compress.convertBase64ToFile(base64str, imgExt);
        });
      setTimeout(function () {
        vm.onUpload();
      }, 500);
    },
    onUpload() {
      let vm = this;
      const storageRef = firebase
        .storage()
        .ref(
          "web/profile_images/profile" +
            moment().format("YYYYMMDDHHmmss") +
            vm.fileName
        )
        .put(this.selectedFile);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          // console.log(error.message);
          vm.$message({
            message: error.message,
            showClose: true,
            type: "error",
          });
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            if (url) {
              vm.userDoc.url = url || "";
              vm.userDoc.user_id = vm.user._id;
              vm.updateProfileApi(vm.userDoc);
            }
          });
        }
      );
    },
    updateProfileApi(doc) {
      let vm = this;
      let url = provider.baseURL + `/user/uploadProfilePhoto`;
      return new Promise((resolve, reject) => {
        axios.post(url, doc, { headers: { token: provider.token } }).then(
          res => {
            if (res.data.code === 201) {
              resolve(res.data);
              vm.user.url = doc.url;
              localStorage.url = doc.url;
              vm.$message({
                message: this.$t("uploadSuccess"),
                type: "success",
              });
            }
          },
          error => {
            reject(error);
            vm.$message({
              type: "error",
              message: this.$t("removeFail"),
            });
          }
        );
      });
    },
    phoneNumberAcceptToConfirm(phoneNumberToConfirm) {
      let vm = this;
      const appVerifier = window.recaptchaVerifier;
      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumberToConfirm, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          // console.log("send verification code");
          vm.loading = false;
          this.dialog = true;
        })
        .catch(error => {
          // Error; SMS not sent
          // ...
          // alert(error.message);
          vm.$message({
            message: error.message,
            showClose: true,
            type: "warning",
          });
          vm.loading = false;
          // console.log(error);
        });
    },
    handleSubmit() {
      let vm = this;

      if (vm.$refs.formData.validate()) {
        vm.loading = true;
        // formatting current phone number
        if (vm.phoneNumberToFormat.charAt(0) == "+") {
          vm.phoneNumberToConfirm = vm.phoneNumberToFormat;
        } else {
          vm.phoneNumberToConfirm =
            vm.phoneNumberPrefixToFormat + vm.phoneNumberToFormat;
        }
        // formatting user input phone number
        if (vm.user.phoneNumber.startsWith("0")) {
          let removedZero = vm.user.phoneNumber.slice(1);
          vm.formattedPhoneNumber = vm.user.phoneNumberPrefix + removedZero;
        } else {
          vm.formattedPhoneNumber =
            vm.user.phoneNumberPrefix + vm.user.phoneNumber;
        }
        // vm.phoneNumberToConfirm =
        //   vm.user.phoneNumberPrefix + vm.user.phoneNumber;
        let url = provider.baseURL + `/users/checkPhoneNumber`;
        return new Promise((resolve, reject) => {
          axios
            .post(
              url,
              {
                phoneNumberPrefix: vm.user.phoneNumberPrefix,
                phoneNumber: vm.formattedPhoneNumber,
              },
              { headers: { token: provider.token } }
            )
            .then(
              res => {
                if (res.data.code === 403) {
                  resolve(res.data);
                  if (vm.formattedPhoneNumber === vm.phoneNumberToConfirm) {
                    vm.phoneNumberAcceptToConfirm(vm.phoneNumberToConfirm);
                    // console.log("same phone number");
                  } else {
                    // console.log("phone number exist");
                    // vm.phoneNumberAcceptToConfirm(vm.phoneNumberToConfirm);
                    vm.loading = false;
                    vm.$message({
                      message: vm.$t("phoneNumberExist"),
                      showClose: true,
                      type: "warning",
                    });
                    window.grecaptcha.reset(window.recaptchaWidgetId);
                  }
                } else {
                  vm.phoneNumberAcceptToConfirm(vm.phoneNumberToConfirm);
                  // console.log("phone not exist");
                }
              },
              error => {
                reject(error);
                vm.$message({
                  message: error.message,
                  showClose: true,
                  type: "error",
                });
              }
            );
        });
      } else {
        window.grecaptcha.reset(window.recaptchaWidgetId);
      }
    },
    queryProperty: _.debounce(function () {
      let vm = this;
      vm.fetchProperty({
        search: "",
        filter: "",
        skip: vm.skip || 0,
        limit: vm.limit || 12,
        sortBy: "",
        sortDesc: "",
        locationId: "",
        type: "",
        minPrice: "",
        maxPrice: "",
        groupType: "",
        status: "",
        userId: vm.currentUser || "",
        isWeb: true,
        countryId: "",
      });
    }, 500),
    goToDetail(link, params) {
      let vm = this;
      if (vm.$router.history.current.path !== link) {
        this.$router.push({
          name: link,
          params: params,
        });
      }
    },
    focusOnPhoneInput() {
      this.$nextTick(() => this.$refs.phoneInput.focus());
    },
    verifyCode() {
      // window.confirmationResult = undefined;
      let vm = this;
      if (vm.$refs.codeInputFormData.validate()) {
        vm.loading = true;
        // [START auth_phone_verify_code]
        // const code = this.getCodeFromUserInput();
        // console.log(code + "verify");
        window.confirmationResult
          .confirm(vm.user.smsCode)
          .then(() => {
            // User signed in successfully.
            vm.dialog = false;
            // alert("successfully signed in user account");
            // ...
            let newDoc = {};
            newDoc.user_id = vm.user._id;
            newDoc.phoneNumberPrefix = vm.user.phoneNumberPrefix;
            // console.log("before update phone " + newDoc.phoneNumberPrefix);
            newDoc.phoneNumber = vm.formattedPhoneNumber;
            newDoc.fullName = vm.user.profile.fullName;
            newDoc.email = vm.user.email;
            newDoc.telegram = vm.user.telegram;
            newDoc.facebook = vm.user.facebook;
            newDoc.dob = vm.user.dob;
            newDoc.type = vm.user.type;
            newDoc.education = vm.user.education;
            newDoc.occupation = vm.user.occupation;
            newDoc.yearly_income = vm.user.yearly_income;
            newDoc.job_level = vm.user.job_level;
            newDoc.address = vm.user.address;
            let url = provider.baseURL + `/users/update_profile`;
            return new Promise((resolve, reject) => {
              axios
                .post(url, newDoc, { headers: { token: provider.token } })
                .then(
                  res => {
                    if (res.data.code === 201) {
                      resolve(res.data);
                      vm.loading = false;
                      // console.log(
                      //   "after update phone " + newDoc.phoneNumberPrefix
                      // );

                      vm.$message({
                        message: this.$t("success"),
                        showClose: true,
                        type: "success",
                      });
                      vm.phoneNumberToFormat = newDoc.phoneNumber;
                      vm.user.smsCode = "";
                    }
                  },
                  error => {
                    reject(error);
                    vm.loading = false;
                    vm.$message({
                      message: error.message,
                      showClose: true,
                      type: "error",
                    });
                  }
                );
            });

            /*Meteor.call("base_updateProfile", vm.user._id, vm.user, Constants.secret, (err, result) => {
          if (!err) {
            this.$message({
              message: this.$t('successNotification'),
              showClose: true,
              type: 'success'
            });
            vm.updateUser(Meteor.user());
            vm.loading = false;
            /!*if (vm.user.staffId) {
                Meteor.call("updateStaffBank", vm.dataObj, Constants.secret, (e, r) => {
                    if (e) {
                        console.log(e.message);
                        this.$message({
                            message: e.message,
                            showClose: true,
                            type: 'error'
                        });
                    }
                })
            }*!/
          } else {
            console.log(err.message);
            this.$message({
              message: err.message,
              showClose: true,
              type: 'error'
            });

          }
        })*/
          })
          .catch(error => {
            // User couldn't sign in (bad verification code?)
            // ...
            // console.log(error);
            vm.user.smsCode = "";
            // alert(error.message);
            vm.$message({
              message: error.message,
              showClose: true,
              type: "error",
            });
            vm.loading = false;
            window.grecaptcha.reset(window.recaptchaWidgetId);
          });
        // [END auth_phone_verify_code]
      }
    },
    cancelConfirmation() {
      let vm = this;
      this.dialog = false;
      vm.user.smsCode = "";
    },
    checkRole(roles){
      return localStorage.userType===roles;
    },
    renewProperty(id) {
      console.log('renew' + id)
      let vm = this;
      let url = provider.baseURL + `/property/renew`;
      return new Promise((resolve, reject) => {
        axios
          .post(url, { propertyId: id, userId: vm.currentUser }, { headers: { token: provider.token } })
          .then(
            (res) => {
              if (res.data.code === 201) {
                resolve(res.data);
                vm.$message({
                  message: vm.$t("success"),
                  showClose: true,
                  type: "success",
                });
                vm.queryProperty();
              }
            },
            (error) => {
              reject(error);
              vm.$message({
                message: error.message,
                showClose: true,
                type: "error",
              });
            }
          );
      });
    },
    removeProperty(doc) {
      let vm = this;
      vm.$confirm(this.$t("Do you want to remove?"), this.$t("warning"), {
        confirmButtonText: this.$t("ok"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          let url = provider.baseURL + `/property/remove`;
          return new Promise((resolve, reject) => {
            axios
              .post(
                url,
                { propertyId: doc._id },
                { headers: { token: provider.token } }
              )
              .then(
                (res) => {
                  if (res.data.code === 201) {
                    resolve(res.data);
                    vm.$message({
                      message: this.$t("removeSuccess"),
                      type: "success",
                    });
                    vm.queryProperty();
                    vm.removePropertyImages(doc.urlList);
                  }
                },
                (error) => {
                  reject(error);
                  vm.$message({
                    type: "error",
                    message: this.$t("removeFail"),
                  });
                }
              );
          });
        })
        .catch(() => {
          vm.$message({
            type: "info",
            message: this.$t("removeCancel"),
          });
        });
    },
    removePropertyImages(urlList) {
      // remove property images from cloudflare
      let urlDelete = provider.baseURL + `/property/removeCloudFlareImages`;
      let imgToDelete = urlList.filter(
        (url) => url.indexOf("imagedelivery") > -1
      );
      axios
        .post(
          urlDelete,
          { urlList: imgToDelete },
          { headers: { token: provider.token } }
        )
        .then(
          (res) => {
            if (res.data.data == true) {
              console.log("deleted images");
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
    updateStatus(id, status) {
      let vm = this;
      vm.updateStatusOnPrice = status;
      vm.updateId = id;
      if (status === "Already Deposit") {
        vm.depositForm = true;
      } else if (status === "Already Sold") {
        vm.soldForm = true;
      } else {
        let url = provider.baseURL + `/property/updateStatus`;
        return new Promise((resolve, reject) => {
          axios
            .post(
              url,
              { propertyId: id, status },
              { headers: { token: provider.token } }
            )
            .then(
              (res) => {
                if (res.data.code === 201) {
                  resolve(res.data);
                  vm.$message({
                    message: vm.$t("success"),
                    showClose: true,
                    type: "success",
                  });

                  let ind = vm.propertyList.findIndex((obj) => obj._id === id);
                  vm.propertyList[ind].status = status;
                }
              },
              (error) => {
                reject(error);
                vm.$message({
                  message: error.message,
                  showClose: true,
                  type: "error",
                });
              }
            );
        });
      }
    },
    updateStatusWithPrice() {
      let vm = this;
      let price;
      if (vm.updateStatusOnPrice === "Already Deposit") {
        price = vm.depositPrice;
      } else if (vm.updateStatusOnPrice === "Already Sold") {
        price = vm.soldPrice;
      }
      let url = provider.baseURL + `/property/updateStatus`;
      return new Promise((resolve, reject) => {
        axios
          .post(
            url,
            {
              propertyId: vm.updateId,
              status: vm.updateStatusOnPrice,
              price: price,
            },
            { headers: { token: provider.token } }
          )
          .then(
            (res) => {
              if (res.data.code === 201) {
                resolve(res.data);
                vm.$message({
                  message: vm.$t("success"),
                  showClose: true,
                  type: "success",
                });

                let ind = vm.propertyList.findIndex(
                  (obj) => obj._id === vm.updateId
                );
                vm.propertyList[ind].status = vm.updateStatusOnPrice;
                vm.depositForm = false;
                vm.soldForm = false;
              }
            },
            (error) => {
              reject(error);
              vm.$message({
                message: error.message,
                showClose: true,
                type: "error",
              });
            }
          );
      });
    },
  },
  watch: {
    page(val) {
      let vm = this;
      vm.loading = true;
      vm.skip = 12 * (val - 1);
      vm.limit = 12 + 12 * (val - 1);
      vm.queryProperty(vm.skip, vm.limit);
    },
  },
  created() {
    let vm = this;

    let userId = vm.$route.params.userId || localStorage.id;
    vm.currentUser = userId || "";
    vm.isFreeMember = vm.checkRole("Member");
    let url = provider.baseURL + `/users/byId?id=${userId}`;
    return new Promise((resolve, reject) => {
      axios.get(url, { headers: { token: provider.token } }).then(
        res => {
          if (res.data.code === 201) {
            resolve(res.data);
            vm.user._id = (res.data.data && res.data.data._id) || "";
            vm.user.username = res.data.data.username;
            vm.user.profile.fullName = res.data.data.profile.fullName;
            vm.user.userType = res.data.data.userType || "";
            vm.user.phoneNumberPrefix = res.data.data.phoneNumberPrefix;
            vm.phoneNumberToFormat = res.data.data.phoneNumber;
            vm.user.email = res.data.data.email || "";
            vm.user.url = res.data.data.url || "";
            vm.user.telegram = res.data.data.telegram || "";
            vm.user.dob = res.data.data.dob || "";
            vm.user.education = res.data.data.education || "";
            vm.user.occupation = res.data.data.occupation || "";
            vm.user.facebook = res.data.data.facebook || "";
            vm.user.job_level = res.data.data.job_level || "";
            vm.user.yearly_income = res.data.data.yearly_income || "";
            vm.user.type = res.data.data.type || "";
            vm.phoneNumberPrefixToFormat = res.data.data.phoneNumberPrefix || "";
            vm.user.agentId = res.data.data.agentId || "";
            vm.user.agencyId = res.data.data.agencyId || "";
            // to show data in textfiled, need to format in web
            if (vm.phoneNumberToFormat.charAt(0) == "+") {
              vm.user.phoneNumber = vm.phoneNumberToFormat.replace(
                res.data.data.phoneNumberPrefix,
                ""
              );
            } else {
              vm.user.phoneNumber = vm.phoneNumberToFormat;
            }
            vm.queryProperty(vm.user._id);
            if(vm.user.userType === "Agent"){
              let url1 = provider.baseURL + `/agent/byId?agentId=${vm.user.agentId}`;
              axios.get(url1, { headers: { token: provider.token }}).then(
                res => {
                  if(res.data.code === 201){
                    vm.agentDoc = res.data.data;
                    vm.user.address = vm.agentDoc.address;
                  }
                }
              )
            }else if(vm.user.userType === "Agency"){
              let url1 = provider.baseURL + `/agency/byId?agencyId=${vm.user.agencyId}`;
              axios.get(url1, { headers: { token: provider.token }}).then(
                res => {
                  if(res.data.code === 201){
                    vm.agentDoc = res.data.data;
                    vm.user.address = vm.agentDoc.address;
                  }
                }
              )
            }
          }
        },
        error => {
          reject(error);
          vm.$message({
            message: error.message,
            showClose: true,
            type: "error",
          });
        }
      );
    });
  },
};
</script>